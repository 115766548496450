import { useCallback, useState } from 'react'
import { getCurrentUserDetails } from '../Services/LoginService'
import { fetchUserAttributes } from '@aws-amplify/auth'
import { ICommunity, IUserAttributes } from '../interfaces'
import { refreshTokenKeys } from '../interfaces'
import {
  getCommunitiesData,
  getRedirectiontoken,
} from '../Services/WidgetService'
import { IUser } from '../interfaces'
import { useNavigate } from '@tanstack/react-location'
import { getEnvironment } from '../Environment'
import { getUserAttributes } from '../Services/GraphQLAPI'
import { getSalesPadInfo } from '../Services/WidgetService'
import { usePostPayload } from './PostPayloadContext'
import useAutoLogin from './AutoLoginForm'
import { getAwsConfig } from '../aws-exports'

const environment = getEnvironment()

const LoginComponentUserAttributes = () => {
  const {
    setPostPayload,
    setGraphQlUserAttributes,
    setOpenSalesPadModal,
    setISalesPadData,
    salesPadData,
  } = usePostPayload()
  const autoLogin = useAutoLogin()
  const navigate = useNavigate()
  const [isWidget] = useState(false)
  const [userAttributes, setUserAttributes] = useState<IUserAttributes>({
    redirectUrl: '',
    orgsInfo: [],
    orgsData: [],
    ErrorAccess: false,
    noAccess: false,
    spaceAccess: false,
    myplaceAccess: false,
    bothAccess: false,
    displayUserName: '',
    formUrl: '',
    shopFormsubmitUrl: '',
    CognitoIdentityServiceProvider: 'CognitoIdentityServiceProvider',
    userView: false,
    user: '',
    salesPadUserAssociatedOrgs: {
      Data: [], // populate as needed
      status: '',
    },
    errorMessage: '',
    signedIn: false,
    greeting: '',
    userName: '',
    userId: '',
    postPayload: '',
    promoStatus: false,
    myplaceInfo: [],
    spaceInfo: [],
    screenName: '',
    language: '',
    V3Access: {
      acessType: '',
      v3Logo: '',
    },
    displaySalespadOrgs: false,
    email: '',
  })

  const generateFormSubmitUrl = (): string => {
    const locationUrl = window.location.host
    const splitedURL = locationUrl.split('.')
    const urlPrefix = splitedURL[0]
    if (
      urlPrefix.toLowerCase() === 'staging-react' ||
      urlPrefix.toLowerCase() === 'staging' ||
      urlPrefix.toLowerCase() === 'qa' ||
      urlPrefix.toLowerCase() === 'dev' ||
      urlPrefix.toLowerCase() === 'www' ||
      urlPrefix.toLowerCase() === 'myplaceforparts' ||
      urlPrefix.includes('localhost')
    ) {
      environment.subDomain = ''
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        shopFormsubmitUrl: `https://${environment.formSubmitUrl}`,
      }))
      return `https://${environment.formSubmitUrl}`
    } else {
      // Handle custom subdomains. eg: autowares.myplaceforparts.com
      environment.subDomain = urlPrefix
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        shopFormsubmitUrl: `https://${urlPrefix}.${environment.formSubmitUrl}`,
      }))
      return `https://${urlPrefix}.${environment.formSubmitUrl}`
    }
  }

  const shouldDisplayV3Logo = (userAttributes: IUserAttributes): boolean => {
    return (
      userAttributes.V3Access &&
      (userAttributes.V3Access?.acessType == '1' ||
        userAttributes.V3Access?.acessType == '2') &&
      userAttributes.salesPadUserAssociatedOrgs !== undefined
    )
  }

  const handleLoginUser = useCallback((userInfo: IUser) => {
    const communitiesInfo = userInfo

    setUserAttributes((prevAttributes) => ({
      ...prevAttributes,
      orgsInfo: communitiesInfo.orgs,
    }))

    if (communitiesInfo.isAssignedToSpace) {
      if (
        communitiesInfo.isAssignedToSpace &&
        communitiesInfo.isAssignedToMyPlace
      ) {
        setUserAttributes((prevAttributes) => ({
          ...prevAttributes,
          bothAccess: true,
        }))
      } else if (
        !communitiesInfo.isAssignedToSpace &&
        !communitiesInfo.isAssignedToMyPlace
      ) {
        setUserAttributes((prevAttributes) => ({
          ...prevAttributes,
          bothAccess: false,
        }))
      } else {
        setUserAttributes((prevAttributes) => ({
          ...prevAttributes,
          spaceAccess: true,
        }))
      }
    } else if (communitiesInfo.isAssignedToMyPlace) {
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        myplaceAccess: true,
      }))
    } else {
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        bothAccess: false,
      }))
    }

    const communitiesData = communitiesInfo.communities
    const myplaceInfo = communitiesData.filter(
      (community) => community.community === 'MyPlace'
    )
    const spaceInfo = communitiesData.filter(
      (community) => community.community === 'Alliance Connect'
    )

    setUserAttributes((prevAttributes) => ({
      ...prevAttributes,
      myplaceInfo,
      spaceInfo,
    }))
  }, [])

  const getCommunitiesInfo = useCallback(
    async (userId: string, token: string) => {
      try {
        const response = await getCommunitiesData(userId, token)
        if (!response || response.data.length === 0) {
          setUserAttributes((prevAttributes) => ({
            ...prevAttributes,
            errorMessage: response?.message || '',
            userView: false,
            noAccess: true,
          }))
          if (response?.message === 'User is Not Exist') {
            navigate({
              to: '/no-access',
            })
          }
        } else {
          setUserAttributes((prevAttributes) => ({
            ...prevAttributes,
            formUrl: response.redirectUrl,
            noAccess: false,
            V3Access: {
              acessType: response.data[0].access.accessType,
              v3Logo: response.data[0].access.v3logo,
            },
            orgsInfo: [...response.data[0].orgs],
            userView: false,
          }))
          handleLoginUser(response.data[0])
          environment.userName = response?.data[0].screenName
          window.localStorage.setItem('userName', response?.data[0].screenName)
        }
      } catch {
        setUserAttributes((prevAttributes) => ({
          ...prevAttributes,
          formUrl: environment.formSubmitUrl,
          userView: false,
          V3Access: {
            acessType: '0',
            v3Logo: '',
          },
          ErrorAccess: true,
        }))
      }
    },
    [handleLoginUser, navigate]
  )

  const HandleSignIn = (
    orgInfo: ICommunity[],
    userAttributesInfo: IUserAttributes
  ) => {
    if (orgInfo[0]?.orgName) {
      autoLogin(
        orgInfo[0].friendlyURL,
        userAttributesInfo.language,
        userAttributesInfo.formUrl
      )
    }
    if (orgInfo[0].community === 'MyPlace') {
      autoLogin(
        '',
        userAttributesInfo.language,
        userAttributesInfo.shopFormsubmitUrl
      )
    } else {
      autoLogin(
        orgInfo[0].friendlyURL,
        userAttributesInfo.language,
        userAttributesInfo.formUrl
      )
    }
  }

  const handleV3SignIn = (userAttributes: IUserAttributes) => {
    if (salesPadData && salesPadData.length > 0) {
      setOpenSalesPadModal(true)
      return
    }

    const idToken = localStorage.getItem(
      `${userAttributes.CognitoIdentityServiceProvider}.${getAwsConfig().userPoolClientId}.${userAttributes.userName}.${refreshTokenKeys.idToken}`
    )
    getRedirectiontoken(
      userAttributes.userName,
      window.location.origin,
      idToken ? idToken : ''
    ).then((response) => {
      if (environment.subDomain === '') {
        window.location.href = `https://${environment.v3Url}/auto-login?token=${response.data.token}&impersonate=false&salespad=false`
      } else {
        window.location.href = `https://${environment.subDomain}.${environment.v3Url}/auto-login?token=${response.data.token}&impersonate=false&salespad=false`
      }
    })
  }

  const getSalesPads = (orgId: string, userId: string, token: string) => {
    getSalesPadInfo(orgId, userId, token)
      .then((resp) => {
        setUserAttributes((prevAttributes) => ({
          ...prevAttributes,
          salesPadUserAssociatedOrgs: {
            Data: resp?.Data,
            status: resp?.status,
          },
        }))
        setISalesPadData(resp?.Data ? resp.Data : [])
      })
      .catch(() => {
        // Handle errors if needed
        // console.error('Error fetching sales pads:', error)
      })
  }

  const getUserInfo = useCallback(
    async (userId: number, idToken: string) => {
      const userInfo = await getUserAttributes(userId, idToken)
      setUserAttributes((prevAttributes) => ({
        ...prevAttributes,
        language: userInfo.language,
      }))

      setGraphQlUserAttributes(userInfo)
      if (userInfo.EULA_accepted === 'true') {
        getCommunitiesInfo(String(userInfo.userId), idToken)
        getSalesPads(userInfo.orgId, userInfo.userId, idToken)
      } else if (userInfo.EULA_accepted === 'false') {
        navigate({
          to: '/terms-and-conditions',
        })
      }
      return userInfo
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      getCommunitiesInfo,
      navigate,
      setGraphQlUserAttributes,
      userAttributes.userName,
    ]
  )

  const fetchUserDetails = useCallback(async () => {
    const userDetails = await getCurrentUserDetails()
    const userInformation = await fetchUserAttributes()

    setUserAttributes((prevAttributes) => ({
      ...prevAttributes,
      userName: userDetails.username,
      signedIn: !!userDetails.username,
      displayUserName: String(userInformation.preferred_username),
      userId: userDetails.userId,
    }))

    const awsmobile = getAwsConfig()

    setPostPayload({
      orgId: '',
      userId: userDetails.userId,
      userName: userDetails.username,
      LastAuthUser: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${refreshTokenKeys.LastAuthUser}`
      ),
      refreshToken: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.refreshToken}`
      ),
      clockDrift: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.clockDrift}`
      ),
      deviceGroupKey: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.deviceGroupKey}`
      ),
      deviceKey: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.deviceKey}`
      ),
      accessToken: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.accessToken}`
      ),
      randomPasswordKey: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.accessToken}`
      ),
      idToken: localStorage.getItem(
        `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.idToken}`
      ),
    })
    const idToken = localStorage.getItem(
      `${userAttributes.CognitoIdentityServiceProvider}.${awsmobile.userPoolClientId}.${userDetails.username}.${refreshTokenKeys.idToken}`
    )

    window.localStorage.setItem('userName', String(userDetails.username))
    window.localStorage.setItem('token', String(idToken))
    getCommunitiesInfo(userDetails.username || '', idToken || '')
    getUserInfo(Number(userDetails.username || ''), String(idToken))
    generateFormSubmitUrl()
  }, [
    getCommunitiesInfo,
    getUserInfo,
    setPostPayload,
    userAttributes.CognitoIdentityServiceProvider,
  ])

  return {
    userAttributes,
    shouldDisplayV3Logo,
    widgetMode: isWidget,
    handleV3SignIn,
    handleSignIn: HandleSignIn,
    fetchUserDetails,
    setUserAttributes,
  }
}

export default LoginComponentUserAttributes
